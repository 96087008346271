<script setup lang="ts">
const configStore = useConfigStore()
const { config, links } = storeToRefs(configStore)
const { isMobileNavBarOpen } = useDashboard()
</script>

<template>
    <section class="mobileHeader pu">
        <div class="flex gap1 items-center justify-between">
            <ElementsIcon icon="fa-regular-bars" @click="isMobileNavBarOpen = true"/>
            <NuxtLink class="max-w-64 headerNav-link" :to="links.dashboard" :title="config?.establishment.companyName">
                <NuxtImg :src="`${links.cdnUrl}/${config?.establishment.entityId}/${config?.establishment.id}/images/logo.png`" placeholder="/img/missing-img.png" class="max-w-40 max-h-20"/>
            </NuxtLink>
        </div>
    </section>
</template>

<style scoped lang="scss">
.mobileHeader {   
    background-color: #ffffff;
    & svg {
        --icon-size-default: 2rem;
        cursor: pointer;
        transition: color var(--animation-site-duration) linear;
        &:hover {
            color: var(--primaryColor1);
        }
    }
}
</style>