<script setup lang="ts">
import ButtonDropDownList from "@/components/Elements/ButtonDropDownList.vue";
import type { SearchType } from "~/types/interfaces";
import { searchTypesId } from "~/types/datas";
const { links } = useConfigStore()
const searchTypes = ref<SearchType[]>([{
    id: searchTypesId.anyNumber,
    label: "Recherche globale <span>(par défaut)</span>",
    sprite: "globe"
}, {
    id: searchTypesId.oeNumber,
    label: "Recherche par numéro OE",
    sprite: "arrow-left-right"
}, {
    id: searchTypesId.comparableNumber,
    label: "Recherche par référence concurrente",
    sprite: "list-number"
}, {
    id: searchTypesId.articleNumber,
    label: "Recherche par numéro d'article",
    sprite: "hashtag"
}, {
    id: searchTypesId.tradeNumber,
    label: "Recherche par référence commerciale",
    sprite: "fa-regular-building"
}, {
    id: searchTypesId.shop,
    label: "Recherche dans la boutique",
    sprite: "shop"
}]);

const formId = useId();
const userInput = ref<string>("");
const defaultSelectedType = ref<number>(searchTypesId.anyNumber);
const selectedSearchTypeId = ref<number>(defaultSelectedType.value);
const listOpen = ref<boolean>(false);
const searchTypeSelected = computed((): SearchType => searchTypes.value.filter(type => type.id === selectedSearchTypeId.value)[0]);

const searchByOEM = () => {
    if(selectedSearchTypeId.value !== searchTypesId.shop) {
        return navigateTo(`${links.productList}?search=${userInput.value}&searchType=${selectedSearchTypeId.value}`)
    }

    return navigateTo(`${links.shopSearch}?search=${userInput.value}`)
};

</script>
<template>
    <div class="searchBar">   
        <ButtonDropDownList
            class="searchBar-dropdown"
            :label="searchTypeSelected.id !== defaultSelectedType ? searchTypeSelected.label : 'Recherche globale'"
            :sprite="searchTypeSelected.sprite"
            :isOpen="listOpen"
            @click="listOpen = $event"
        >
            <ul class="no-list-style">
                <li v-for="(searchType, index) in searchTypes" :key="`${searchType.id}${index}`">
                    <button class="searchBar_buttonType" @click="selectedSearchTypeId = searchType.id; listOpen = !listOpen">
                        <ElementsIcon :icon="searchType.sprite"/>
                        <span class="label" v-html="searchType.label"></span>
                    </button>
                </li>               
            </ul>
        </ButtonDropDownList>    
        <label :id="`searchBar_${formId}`" class="searchBar_search">
            <input 
                class="form-control input-num-client searchBar_input"
                type="text" 
                v-model="userInput"
                @keydown.enter="searchByOEM"
                @click="listOpen = false"
                placeholder="Recherche par référence..."
                aria-label="Rechercher par référence"
            />
            <ElementsButtonLinear preIcon="fa-solid-magnifying-glass" @click="searchByOEM" class="buttonSearchBar"/>
        </label>
    </div>           
</template>

<style lang="scss">
.searchBar {
    width: 100%;
    position: relative;
    display: flex;

    & ul {
        margin: 0;
        padding: 1rem;
        border: 1px var(--primaryColor1) solid;
        & li {
            line-height: 2rem;            
        }
    }

    &_search {
        display: flex;
        gap: 1rem;
        align-items: center;
        min-width: 25%;       
    }

    &_buttonType {
        border: none;
        display: flex;
        align-items: center;
        gap: 1rem;
        background-color: transparent;
        transition: color var(--animation-site-duration) linear;
            &:hover {
                color: var(--primaryColor1);
            }
        & svg {
            --icon-size: 20px;
            width: var(--icon-size);
            min-width: var(--icon-size);
            height: var(--icon-size);
            min-height: var(--icon-size);
            color: var(--primaryColor1);
        }
        & .label {
            white-space: nowrap;
            
            & span { 
                color: var(--neutralColor3);
            }
        }
    }   
}
.buttonSearchBar {
    min-height: 40px;
}
.searchBar_input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px var(--primaryColor1) solid;
    border-left: none;
    min-height: 40px;
    &:focus {
        border-color: var(--primaryColor1);
        box-shadow: 0 0 4px var(--primaryColor1);
    }
}
@media all and (max-width: 1000px) {
    .searchBar {
        flex-direction: column;
        gap: .5rem;
        &_search {
            flex-direction: column;
            gap: .5rem;
        }
    }
    .searchBar_input {
        border-radius: var(--roundedBorder);
        border-left: 1px var(--primaryColor1) solid;

    }
    .buttonSearchBar {
        width: 100%;
    }
    .searchBar-dropdown {
        width: 100%;

    }
    .searchBar-dropdown button {
        width: 100%;
        border-radius: var(--roundedBorder);
        justify-content: space-between;
    }
}
</style>