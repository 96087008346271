<script setup lang="ts">
import type { Tab } from '~/types/interfaces';

const { isSearchVehicleOpen } = useDashboard()
const tabs = <Tab[]>[{
    id: 1,
    icon: "immatriculation",
    title: "Par immatriculation"
}, {
    id: 2,
    icon: "car-front",
    title: "Par véhicule"
}, {
    id: 3,
    icon: "hashtag",
    title: "Par VIN"
}]

const currentTab = ref<Tab>(tabs[0]);

</script>

<template>
    <ElementsModal :isOpen="isSearchVehicleOpen" title="Cherchez votre véhicule" @close="isSearchVehicleOpen = false">
        <template #content>
            <section class="flex flexColumn flex1">
                <header class="flex">
                    <button 
                        class="btn-tabs" 
                        :class="{active: currentTab.id === tab.id}" 
                        @click="currentTab = tab" 
                        v-for="tab in tabs" 
                        :key="tab.id"
                    >
                        <ElementsIcon v-if="tab.icon" :icon="tab.icon"/>
                        {{ tab.title }}
                    </button>
                </header>  

                <div 
                    class="vehicleSearchTab flex-1 flex items-center" 
                    v-if="currentTab.id === 1"
                >
                    <ElementsSearchNumberPlate 
                        class="flex-1"
                        :isLabel="false"
                    />
                </div>
                <div 
                    class="vehicleSearchTab flex-1 flex items-center" 
                    v-if="currentTab.id === 2"
                >
                    <VehicleByModel class="flex-1" />
                </div>

                <div 
                    class="vehicleSearchTab flex-1 flex items-center" 
                    v-if="currentTab.id === 3"
                >
                    <VehicleByVin
                        class="flex-1" 
                        :isLabel="false"
                    />
                </div>
            </section>
        </template>
    </ElementsModal>
</template>

<style scoped>
.vehicleSearchTab {
    background-color: var(--primaryColor3);
    padding: 1.875rem 8.125rem 2.5rem 8.125rem;
    border-bottom-left-radius: var(--roundedBorder);
    border-bottom-right-radius: var(--roundedBorder);
}

.btn-tabs {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 0;
}

@media all and (max-width: 768px) {
    .vehicleSearchTab {
        padding: 2.5rem;
    }
}
</style>